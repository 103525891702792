<template>
  <div class="section-with-image-and-items py-5 mt-5">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <h2 class="font-weight-bold">{{props.title}}</h2>
        </div>
        <div class="col-12 text-center mb-3">
          <p class="lead text-muted">{{props.description}}</p>
        </div>
        <div class="col-12 col-lg-6 d-flex align-items-center justify-content-center">
          <img v-bind:src="props.items.imageURL" alt="Description image" height="360">
        </div>
        <div class="col-12 col-lg-6">
          <div class="row">
            <div v-for="(item, index) in props.items.sections"
              v-bind:key="index"
              class="col-12">
              <div class="media features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary mr-3 mt-2">
                  <FeatherIcon/>
                </div>
                <div class="media-body">
                  <h4 class="title">{{item.title}}</h4>
                  <p class="text-muted para mb-0"> {{item.description}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { FeatherIcon } from "vue-feather-icons";
  export default {
    props:{
      props:Object,
    },
    components: {
      FeatherIcon,
    },
  };
</script>